<script lang="ts" setup>
import { toast } from 'vue-sonner'
import { ErrorMessage, Field, Form, useForm } from 'vee-validate'
import { z } from 'zod'

const show = defineModel<boolean>('show')

const sent = ref(false)

const message = defineModel('message', { type: String })

const schema = computed(() => z.object({
  message: z.string().min(1).max(1000),
}))

const feedbackFormSchema = toTypedSchema(schema.value)

const { errors, handleSubmit, resetForm, setFieldValue } = useForm({
  validationSchema: feedbackFormSchema,
  initialValues: { message: message.value },
})

const { $client } = useNuxtApp()

const sendForm = handleSubmit(async (data) => {
  const response = await $client.external.sendFeedbackRequest.mutate({
    ...data,
    requested_at: new Date(),
  })

  if (!response?.success) {
    toast.error('Failed to send feedback, please try again later')
    return
  }

  message.value = ''
  sent.value = true

  setTimeout(() => {
    sent.value = false
    message.value = ''
    resetForm()
    show.value = false
  }, 5000)
})

watch(message, (value) => {
  setFieldValue('message', value)
})

watch(show, (value) => {
  if (!value) {
    message.value = undefined
    resetForm()
  }
})
</script>

<template>
  <slot name="trigger">
    <AppButton icon="lucide:message-circle-heart" shape="circle" class="tooltip tooltip-left absolute bottom-3 right-3 z-10" data-tip="Send Feedback" @click="show = true" />
  </slot>
  <DashboardDialogWrapper v-model:show="show">
    <h3 class="text-lg font-bold">
      <iconify-icon icon="lucide:message-circle-heart" /> Send us your feedback!
    </h3>
    <p class="py-4">
      We value your feedback and would love to hear your thoughts.
    </p>

    <form v-if="!sent" class="flex flex-col gap-4 py-6" @submit="sendForm">
      <div class="form-control">
        <label for="message" class="font-bold">
          Message
        </label>
        <Field id="message" name="message" class="textarea textarea-bordered" as="textarea" placeholder="Your feedback..." :class="{ 'input-error': errors.message }" />
        <ErrorMessage as="span" name="message" class="py-1 text-sm text-error">
          {{ errors.message }}
        </ErrorMessage>
      </div>
      <AppButton type="submit" icon="lucide:send" label="Send Feedback" />
    </form>

    <div v-if="sent" class="py-6">
      <div role="alert" class="alert relative">
        <iconify-icon icon="lucide:thumbs-up" />

        <div>
          <h3 class="font-bold">
            Thank you for your feedback!
          </h3>
          <div class="text-xs">
            We appreciate your input and will use it to improve our services.
          </div>
        </div>
      </div>
    </div>
  </DashboardDialogWrapper>
</template>
