<script lang="ts" setup>
import { asDisplayableName, getInitials } from '~/lib/helpers'

const props = withDefaults(defineProps<{
  src?: string | null
  name?: string | null
  alt?: string | null
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  srDescription?: string
}>(), {
  size: 'md'
})

const textSizeClass = computed(() => {
  return {
    xs: 'text-xs',
    sm: 'text-xs',
    md: 'text-base',
    lg: 'text-md',
    xl: 'text-lg'
  }[props.size]
})

const wrapperSizeClass = computed(() => {
  return {
    xs: 'size-6',
    sm: 'size-8',
    md: 'size-10',
    lg: 'size-12',
    xl: 'size-16'
  }[props.size]
})

const formattedName = computed(() => {
  if (props.name) {
    return getInitials(asDisplayableName(props.name))
  }
  return ''
})
</script>

<template>
  <div v-if="props.src" class="avatar">
    <div class="mask mask-hexagon" :class="`${wrapperSizeClass}`">
      <NuxtImg :src="props.src" :alt=" alt || name || srDescription || ''" class="rounded-full" />
    </div>
  </div>
  <div v-else class="avatar placeholder">
    <div class="rounded-full bg-neutral text-neutral-content" :class="`${wrapperSizeClass}`">
      <span :class="`${textSizeClass}`">{{ formattedName }}</span>
      <span class="sr-only">{{ srDescription || name }}</span>
    </div>
  </div>
</template>
