<script lang="ts" setup>
const { data } = useAuth()
const { $featrack } = useNuxtApp()

onMounted(() => {
  if (data.value?.user) {
    $featrack.customers.create(data.value.user.id, {
      customerName: data.value.user.name || undefined,
    })
  }
})
</script>

<template>
  <div class="background-squares relative min-h-screen max-w-full overflow-y-auto overflow-x-hidden">
    <div class="absolute z-0 size-full overflow-x-hidden bg-[rgba(243,243,243,0.7)]  dark:bg-[rgba(20,27,36,0.97)]" />

    <div class="navbar bg-base-100 fixed top-0 z-10">
      <div class="container">
        <button class="btn btn-circle btn-ghost hidden">
          <iconify-icon icon="lucide:menu" class="text-lg" />
        </button>
        <div class="flex-1">
          <NuxtLink to="/dashboard/landing" class="flex  w-10 items-center gap-2 text-lg font-semibold md:text-base">
            <NuxtImg src="/logo_pink.svg" /><span class="hidden text-xl font-semibold md:inline">featrack.io</span>
            <span class="sr-only">Featrack</span>
          </NuxtLink>
        </div>
        <div class="flex flex-none items-center">
          <NuxtLink to="/dashboard/integrations">
            <AppButton icon="lucide:code-xml" shape="circle" variant="ghost" />
          </NuxtLink>
          <ClientOnly>
            <AppThemeSwitcher />
          </ClientOnly>
          <UserDropdown />
        </div>
      </div>
    </div>
    <div class="relative flex">
      <div class="container relative z-[1] min-h-screen overflow-x-hidden pt-16">
        <slot />
      </div>
      <slot name="right" />
      <DashboardFeedbackForm />
    </div>
  </div>
</template>
