<script setup lang="ts">
const { data, signOut } = useAuth()
</script>

<template>
  <div class="dropdown dropdown-end">
    <div tabindex="0" role="button" class="avatar btn btn-circle btn-ghost">
      <AppNameAvatar :name="data?.user?.name || data?.user?.email" :src="data?.user?.image" />
    </div>
    <ul
      tabindex="0"
      class="menu dropdown-content menu-sm z-[1] mt-3 w-52 rounded-box bg-base-100 p-2 shadow"
    >
      <li class="p-2 font-bold italic text-base-content/70">
        {{ data?.user?.name || data?.user?.email }}
      </li>
      <li>
        <NuxtLink to="/dashboard/account">
          Account
        </NuxtLink>
      </li>
      <li>
        <NuxtLink to="/dashboard/settings">
          Settings
        </NuxtLink>
      </li>
      <li @click="() => signOut()">
        <a>Logout</a>
      </li>
    </ul>
  </div>
</template>

<style scoped></style>
